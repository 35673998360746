import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { RecruiterService } from 'src/app/recruiter/services/recruiter.service';
import { selectLoggedInUser } from '../account/account.selectors';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import { getJobs } from '../jobs/jobs.actions';
import { getAllProfiles } from '../profile/profile.actions';
import {
  bookmarkUserProfile,
  createProject,
  getIndFilmMakerProfileDetails,
  getIndFilmMakerProfileDetailsError,
  getIndFilmMakerProfileDetailsSuccess,
  getJobsCreated,
  getJobsCreatedError,
  getJobsCreatedSuccess,
  getProjects,
  getRecruiterDetails,
  getRecruiterDetailsError,
  getRecruiterDetailsSuccess,
  saveNotesForUserProfile,
  showRecruiterProfile,
  updateIndFilmMakerProfileDetails,
  updateLikeForUserProfile,
  updateProjects,
  updateRecruiterProfileDetails,
  updateShortlistName,
  updateShortlistProfiles,
} from './recruiter.actions';

@Injectable()
export class RecruiterEffects {
  constructor(
    private actions$: Actions,
    private recruiterService: RecruiterService,
    private store: Store
  ) {}

  getRecruiter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecruiterDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.recruiterService
          .getRecruiterDetails(
            loggedInDetails.accountId,
            loggedInDetails.registerType
          )
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (response.code === 'CineRush_00006') {
                return showRecruiterProfile({ showRecruiterProfile: true });
              } else {
                this.store.dispatch(
                  showRecruiterProfile({ showRecruiterProfile: false })
                );
                return getRecruiterDetailsSuccess({
                  payload: response.recruiterProfile,
                });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [
                getRecruiterDetailsError({ error: true, message: error }),
              ];
            })
          );
      })
    )
  );

  getJobsCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobsCreated),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.recruiterService
          .getJobsCreated({
            filter: payload.filter,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );

              return getJobsCreatedSuccess({ payload: response });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [getJobsCreatedError({ error: true, message: error })];
            })
          );
      })
    )
  );

  updateRecruiterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRecruiterProfileDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .updateRecruiterProfileDetails({
            ...action.payload,
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return getAllProfiles({
                appliedList: [],
                filter: {},
                registerType: loggedInDetails.registerType,
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return [
                getAllProfiles({
                  appliedList: [],
                  filter: {},
                  registerType: loggedInDetails.registerType,
                }),
              ];
            })
          );
      })
    )
  );

  updateIndipendentFilmMakerProfil$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIndFilmMakerProfileDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .updateIndFilmMakerProfileDetails({
            ...action.payload,
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return getIndFilmMakerProfileDetailsSuccess({
                payload: response.profileResponse,
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return [
                getIndFilmMakerProfileDetailsSuccess({
                  payload: {},
                }),
              ];
            })
          );
      })
    )
  );

  getIndipendentFilmMakerProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIndFilmMakerProfileDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.recruiterService
          .getIndFilmMakerProfileDetails(
            loggedInDetails.accountId,
            loggedInDetails.registerType
          )
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'FAILED' &&
                response.code === 'CineRush_00006'
              ) {
                return showRecruiterProfile({ showRecruiterProfile: true });
              } else {
                this.store.dispatch(
                  showRecruiterProfile({ showRecruiterProfile: false })
                );
                return getIndFilmMakerProfileDetailsSuccess({
                  payload: response.profileResponse,
                });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [
                getIndFilmMakerProfileDetailsError({
                  error: true,
                  message: error,
                }),
              ];
            })
          );
      })
    )
  );

  updateLikeForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLikeForUserProfile),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .likeUserProfile({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            interactedAccountId: action.interactedAccountId,
            interactionFlag: action.interactionFlag,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return action.jobId
                ? getJobs({
                    filter: {},
                    pageNumber: 0,
                    pageSize: 0,
                    sortField: 'createdDtm',
                    sortAscending: false,
                  })
                : getAllProfiles({
                    appliedList: [],
                    filter: {},
                    registerType: loggedInDetails.registerType,
                  });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return action.jobId
                ? [
                    getJobs({
                      filter: {},
                      pageNumber: 0,
                      pageSize: 0,
                      sortField: 'createdDtm',
                      sortAscending: false,
                    }),
                  ]
                : [
                    getAllProfiles({
                      appliedList: [],
                      filter: {},
                      registerType: loggedInDetails.registerType,
                    }),
                  ];
            })
          );
      })
    )
  );

  bookmarkUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bookmarkUserProfile),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .bookMarkUserProfile({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            interactedAccountId: action.interactedAccountId,
            interactionFlag: action.interactionFlag,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return action.jobId
                ? getJobs({
                    filter: {},
                    pageNumber: 0,
                    pageSize: 0,
                    sortField: 'createdDtm',
                    sortAscending: false,
                  })
                : getAllProfiles({
                    appliedList: [],
                    filter: {},
                    registerType: loggedInDetails.registerType,
                  });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return action.jobId
                ? [
                    getJobs({
                      filter: {},
                      pageNumber: 0,
                      pageSize: 0,
                      sortField: 'createdDtm',
                      sortAscending: false,
                    }),
                  ]
                : [
                    getAllProfiles({
                      appliedList: [],
                      filter: {},
                      registerType: loggedInDetails.registerType,
                    }),
                  ];
            })
          );
      })
    )
  );

  saveNotesForUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveNotesForUserProfile),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Saving...' } })
        );
        return this.recruiterService
          .saveNotesForUserProfile({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            targetedAccountId: action.targetedAccountId,
            notes: action.notes,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Notes updated.',
                    show: true,
                  },
                })
              );

              return getAllProfiles({
                appliedList: [],
                filter: {},
                registerType: loggedInDetails.registerType,
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Save notes failed',
                    show: true,
                  },
                })
              );
              return [
                getAllProfiles({
                  appliedList: [],
                  filter: {},
                  registerType: loggedInDetails.registerType,
                }),
              ];
            })
          );
      })
    )
  );

  getProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProjects),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.recruiterService
          .getProjects({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (response.code === 'CineRush_00001') {
                return updateProjects({ projects: response.projects });
              }

              return updateProjects({ projects: [] });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [updateProjects({ projects: [] })];
            })
          );
      })
    )
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProject),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Creating...' } })
        );
        let payload: any = {
          accountId: loggedInDetails.accountId,
          registerType: loggedInDetails.registerType,
          title: action.title,
          description: action.description,
          shortlists: action.shortlists,
          isActive: action.isActive,
        };
        if (action.projectId) {
          payload['projectId'] = action.projectId;
        }

        return this.recruiterService.createProject(payload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Project created.',
                  show: true,
                },
              })
            );

            return getProjects();
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Project creation failed.',
                  show: true,
                },
              })
            );
            return [getProjects()];
          })
        );
      })
    )
  );

  updateShortlistName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateShortlistName),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .updateShortlistName({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            projectId: action.projectId,
            deleteShortlist: action.deleteShortlist,
            isShortListNameUpdated: action.isShortListNameUpdated,
            shortlistName: action.shortlistName,
            oldshortlistName: action.oldshortlistName,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Shortlist updated.',
                    show: true,
                  },
                })
              );

              return getProjects();
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Shortlist update failed.',
                    show: true,
                  },
                })
              );
              return [getProjects()];
            })
          );
      })
    )
  );

  updateShortlistProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateShortlistProfiles),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .updateShortlistProfiles({
            accountId: loggedInDetails.accountId,
            registerType: loggedInDetails.registerType,
            projectId: action.projectId,
            shortListName: action.shortListName,
            targetedAccountId: action.targetedAccountId,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Shortlist updated.',
                    show: true,
                  },
                })
              );

              return getProjects();
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Shortlist update failed.',
                    show: true,
                  },
                })
              );
              return [getProjects()];
            })
          );
      })
    )
  );
}
