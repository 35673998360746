import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  INIT,
  MetaReducer,
  createAction,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { accountReducer } from './account/account.reducer';
import { AccountState } from './account/account.state';
import { coreReducer } from './core/core.reducer';
import { CoreState } from './core/core.state';
import { endorsementsReducer } from './endorsements/endorsements.reducer';
import { EndorsementsState } from './endorsements/endorsements.state';
import { jobsReducer } from './jobs/jobs.reducer';
import { messagesReducer } from './messages/messages.reducer';
import { MessagesState } from './messages/messages.state';
import { profileReducer } from './profile/profile.reducer';
import { ProfileState } from './profile/profile.state';
import { recruiterReducer } from './recruiter/recruiter.reducer';
import { RecruiterState } from './recruiter/recruiter.state';
import { showcaseReducer } from './showcase/showcase.reducer';
import { ShowCaseState } from './showcase/showcase.state';
import { userReducer } from './user/user.reducer';
import { UserState } from './user/user.state';

export interface AppState {
  coreState: CoreState;
  accountState: AccountState;
  profileState: ProfileState;
  recruiterState: RecruiterState;
  jobsState: any;
  messagesState: MessagesState;
  showCaseState: ShowCaseState;
  userState: UserState;
  endorsementsState: EndorsementsState;
}

export const reducers: ActionReducerMap<AppState> = {
  coreState: coreReducer,
  accountState: accountReducer,
  profileState: profileReducer,
  recruiterState: recruiterReducer,
  jobsState: jobsReducer,
  messagesState: messagesReducer,
  showCaseState: showcaseReducer,
  userState: userReducer,
  endorsementsState: endorsementsReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      'coreState',
      'accountState',
      'profileState',
      'recruiterState',
      'jobsState',
      'messagesState',
      'showCaseState',
      'userState',
      'endorsementsState',
    ],
    rehydrate: true,
  })(reducer);
}

export function logout(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return (state, action) => {
    if (action != null && action.type === appLogout.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const appLogout = createAction('[Account] Logout');
export const metaReducers: MetaReducer<AppState>[] = isDevMode()
  ? [localStorageSyncReducer, logout]
  : [localStorageSyncReducer, logout];
