import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const featureKey = 'userState';
export const selectUserState = createFeatureSelector<UserState>(featureKey);

export const selectPostedByProfileDetails = createSelector(
  selectUserState,
  (state: UserState) => state.postedByProfile
);

export const selectNotifications = createSelector(
  selectUserState,
  (state: UserState) => state.notifications
);
