import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getPostedByDetails(accountId: string, registerType: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getindustryprofile,
      { accountId, registerType }
    );
  }

  getNotifications(accountId: string, registerType: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getNotifications,
      { accountId, registerType }
    );
  }

  setNotificationRead(
    accountId: string,
    registerType: string,
    notificationId: string
  ): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.setNotificationRead,
      { accountId, registerType, notificationId }
    );
  }

  clearNotifications(accountId: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.clearNotifications,
      { accountId }
    );
  }
}
