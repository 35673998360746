import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  IProjects,
  IShortlist,
} from 'src/app/recruiter/interfaces/projects.interface';
import { toggleBottomSheet } from 'src/app/store/core/core.actions';
import {
  getProjects,
  updateShortlistProfiles,
} from 'src/app/store/recruiter/recruiter.actions';
import { selectProjects } from 'src/app/store/recruiter/recruiter.selector';
import { CreateShortlistPopupComponent } from '../create-shortlist-popup/create-shortlist-popup.component';
@Component({
  selector: 'shortlist-bottom-popup',
  templateUrl: 'shortlist-bottom-popup.component.html',
})
export class ShortlistBottomPopupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  private projectsSubject: BehaviorSubject<IProjects[]> = new BehaviorSubject<
    IProjects[]
  >([]);
  projects$: Observable<IProjects[]> = this.projectsSubject.asObservable();
  selectedProject: IProjects | null = null;
  selectedShortlist: IShortlist | null = null;
  constructor(
    private store: Store,
    private dialog: MatDialog,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      accountId: string;
      jobId: string;
    }
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getProjects());
    this.subscriptions[0] = this.store
      .select(selectProjects)
      .subscribe((projects) => this.projectsSubject.next(projects));
  }

  close(): void {
    this.store.dispatch(
      toggleBottomSheet({
        show: false,
        data: {
          accountId: '',
          jobId: '',
        },
      })
    );
  }

  save(): void {
    console.log('Save', this.selectedProject, this.selectedShortlist);
    this.store.dispatch(
      updateShortlistProfiles({
        projectId: this.selectedProject?.projectId!,
        shortListName: this.selectedShortlist?.name!,
        targetedAccountId: this.data.accountId,
      })
    );
  }

  createNewShortlist(): void {
    console.log('Create New Shortlist');
    this.dialog.open(CreateShortlistPopupComponent, {
      data: {
        title: 'Create Shortlist',
        data: {
          profileData: '',
          jobId: '',
        },
        positiveBtnLabel: 'Create',
        negativeBtnLabel: 'Cancel',
      },
    });
    this.close();
  }

  selectProject(selectedProject: IProjects) {
    console.log('selectedProject', selectedProject);
    this.selectedProject = selectedProject;
  }

  selectShortlist(shortlist: IShortlist) {
    console.log('selectedShortlist', shortlist);
    this.selectedShortlist = shortlist;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
