import { INotification } from 'src/app/shared/interfaces/notification.interface';

export const initialState: UserState = {
  postedByProfile: {},
  notifications: [],
};

export interface UserState {
  postedByProfile: any;
  notifications: INotification[];
}
