import { createFeatureSelector, createSelector } from '@ngrx/store';
import { constants } from 'src/app/shared/utils/constants';
import { selectLoggedInUser } from '../account/account.selectors';
import { selectJobs } from '../jobs/jobs.selector';
import { RecruiterState } from './recruiter.state';

export const featureKey = 'recruiterState';
export const selectRecruiterState =
  createFeatureSelector<RecruiterState>(featureKey);

export const selectShowRecruiterProfile = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.showRecruiterProfile
);

export const selectRecruiterProfileDetails = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.recruiterDetails
);

export const selectIndFilmMakerProfileDetails = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.indFilmmakerProfile
);

export const selectProfilesFilter = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.profileFilter
);

export const selectRecruiterProfilePhoto = createSelector(
  selectRecruiterState,
  selectLoggedInUser,
  (state: RecruiterState, loggedInUserDetails) => {
    return {
      profileImage: state.recruiterDetails?.signedOrgLogoUrl,
      isLoggedIn:
        loggedInUserDetails.accountId &&
        loggedInUserDetails.registerType === constants.roles.recruiter
          ? true
          : false,
    };
  }
);

export const selectIndFilmMakerrProfilePhoto = createSelector(
  selectRecruiterState,
  selectLoggedInUser,
  (state: RecruiterState, loggedInUserDetails) => {
    return {
      profileImage: state.indFilmmakerProfile?.signedprofileImageUrl,
      isLoggedIn:
        loggedInUserDetails.accountId &&
        loggedInUserDetails.registerType === constants.roles.indFilmMaker
          ? true
          : false,
    };
  }
);

export const selectViewJobDetailsById = createSelector(
  selectRecruiterState,
  selectJobs,
  (state: RecruiterState, { jobs, totalRecords }) =>
    jobs.find((job) => job.jobId === state.viewJobDetailsId)
);

export const selectProjects = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.projects
);
