import { createReducer, on } from '@ngrx/store';
import { setNotifications, setPostedByDetails } from './user.actions';
import { initialState, UserState } from './user.state';

const _userReducer = createReducer(
  initialState,
  on(setPostedByDetails, (state: UserState, { postedByProfile }) => {
    return {
      ...state,
      postedByProfile: postedByProfile,
    };
  }),
  on(setNotifications, (state: UserState, { notifications }) => {
    return {
      ...state,
      notifications: notifications,
    };
  })
);

export function userReducer(state: any, action: any) {
  return _userReducer(state, action);
}
