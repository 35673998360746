import { createAction, props } from '@ngrx/store';
import { EndorsementProfile } from 'src/app/endorsements/interfaces/endorsement-profile.interface';
import { EndorsementResponse } from 'src/app/endorsements/interfaces/endorsements-response.interface';
import { IEndorsemtsRequest } from 'src/app/recruiter/interfaces/endorsements-request.interface';

export const getIndustryProfiles = createAction(
  '[Endorsements] Get Industry Profiles'
);

export const setIndustryProfiles = createAction(
  '[Endorsements] Set Industry Profiles',
  props<{ industryProfiles: EndorsementProfile[] }>()
);

export const getEndorsements = createAction(
  '[Endorsements] Get Endorsements Requests'
);

export const setEndorsements = createAction(
  '[Endorsements] Set Endorsements Requests',
  props<{ endorsements: EndorsementResponse[] }>()
);

export const editEndorsement = createAction(
  '[Endorsements] Edit Endorsement',
  props<{ endorsement: EndorsementResponse }>()
);

export const submitEndorsementRequest = createAction(
  '[Endorsements] Submit Endorsement Request',
  props<{
    endorsemntsWithNotes: [
      {
        targetedAccountId: string;
        notes: string;
      }
    ];
  }>()
);

export const removeEndorsementRequest = createAction(
  '[Endorsements] Remove Endorsement Request',
  props<{ targetedAccountId: string }>()
);

export const updateEndorsementRequest = createAction(
  '[Endorsements] Update Endorsement Request',
  props<{ targetedAccountId: string; notes: string }>()
);

export const getEndorsementsRequestsByUsers = createAction(
  '[Endorsements] Get Endorsements Requests By Users'
);

export const setEndorsementsRequestsByUsers = createAction(
  '[Endorsements] Set Endorsements Requests By Users',
  props<{ endorsements: IEndorsemtsRequest[] }>()
);

export const approveOrDeclineEndorsement = createAction(
  '[Endorsements] Approve Or Decline Endorsement',
  props<{
    targetedAccountId: string;
    isEndorsementApproved: boolean;
    isEndorsementDenied: boolean;
  }>()
);
