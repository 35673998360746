export const environment = {
  env: 'sit',
  api: {
    //baseUrl: 'http://localhost:8080',
    baseUrl: '',
    // baseUrl: 'http://ec2-3-27-66-12.ap-southeast-2.compute.amazonaws.com:8080',
    // baseUrl: 'https://bharatglycols.com',
    health: '/outleap/cinerush/health',
    login: '/outleap/cinerush/api/public/loginUser',
    registerUser: '/outleap/cinerush/api/public/registerUser',
    changePassword: '/outleap/cinerush/api/public/changePassword',
    forgotPassword: '/outleap/cinerush/api/public/forgotPassword',
    verifyEmail: '/outleap/cinerush/api/public/verifyEmail',
    getToken: '/outleap/cinerush/api/public/getToken',
    sendOtp: '/outleap/cinerush/api/public/sendOTP',
    validateOtp: '/outleap/cinerush/api/public/validatOTP',
    allUtilData: '/outleap/cinerush/api/public/getAllUtildata',
    createJob: '/outleap/cinerush/api/createOrUpdateJob',
    createOrUpdateDraft: '/outleap/cinerush/api/createOrUpdateDraft',
    getAllDrafts: '/outleap/cinerush/api/getAllDrafts',
    deleteJob: '/outleap/cinerush/api/deleteJob',
    getJobs: '/outleap/cinerush/api/getAlljobs',
    getJobDetails: '/outleap/cinerush/api/getjobdetails',
    getRecruiterDetails: '/outleap/cinerush/api/getRecruiterProfile',
    updateRecruiterProfile: '/outleap/cinerush/api/updateRecruiterProfile',
    getIndependentFilmMakerProfile:
      '/outleap/cinerush/api/getIndependentFilmMakerProfile',
    updateIndependentFilmMakerProfile:
      '/outleap/cinerush/api/updateIndependentFilmMakerProfile',
    getProfileDetails: '/outleap/cinerush/api/getUserProfile',
    updateProfileDetails: '/outleap/cinerush/api/updateUserProfile',
    applyJob: '/outleap/cinerush/api/applyJob',
    getAllProfiles: '/outleap/cinerush/api/getAllProfiles',
    getChats: '/outleap/cinerush/api/getChats',
    getMessages: '/outleap/cinerush/api/getMessages',
    sendMessage: '/outleap/cinerush/api/sendMessage',
    upload: '/outleap/cinerush/api/upload',
    getMediaFiles: '/outleap/cinerush/api/getMediaFiles',
    setFavorite: '/outleap/cinerush/api/setFavorite',
    setViewed: '/outleap/cinerush/api/setViewed',
    sendFeedBack: '/outleap/cinerush/api/sendFeedBack',
    approveApplicant: '/outleap/cinerush/api/approveApplicant',
    rejectApplicant: '/outleap/cinerush/api/rejectApplicant',
    updateProfileVisibilty: '/outleap/cinerush/api/updateProfileVisibilty',
    createOrUpdateShowCase: '/outleap/cinerush/api/createOrUpdateShowCase',
    getShowCases: '/outleap/cinerush/api/getShowCases',
    likeShowCase: '/outleap/cinerush/api/likeShowCase',
    watchLater: '/outleap/cinerush/api/bookMarkShowCase',
    getStats: '/outleap/cinerush/api/public/getStats',
    deleteMediaFile: '/outleap/cinerush/api/deleteMediaFiles',
    bookMarkUserProfile: '/outleap/cinerush/api/bookMarkUserProfile',
    likeUserProfile: '/outleap/cinerush/api/likeUserProfile',
    getindustryprofile: '/outleap/cinerush/api/getindustryprofile',
    saveNotesForUserProfile: '/outleap/cinerush/api/savenotes',
    shortlistuserprofileforjob:
      '/outleap/cinerush/api/shortlistuserprofileforjob',
    deleteDraft: '/outleap/cinerush/api/deleteDraft',
    getProjects: '/outleap/cinerush/api/getprojects',
    createOrUpdateProject: '/outleap/cinerush/api/createorupdateproject',
    createOrUpdateShortlist: '/outleap/cinerush/api/createorupdateshortlist',
    updateShortlistProfiles: '/outleap/cinerush/api/updateshortlistprofiles',
    getIndustryProfilesForEndorsement:
      '/outleap/cinerush/api/retrieveindustrydetailsforendorsement ',
    removeEndorsementRequest: '/outleap/cinerush/api/removeendorsementrequest',
    getEndorsements: '/outleap/cinerush/api/getendorsements',
    submitEndorsement: '/outleap/cinerush/api/submitendorsement',
    updateEndorsement: '/outleap/cinerush/api/updateendorsement',
    approveOrDenyEndorsement: '/outleap/cinerush/api/approveordenyendorsement',
    getNotifications: '/outleap/cinerush/api/getusernotifications',
    setNotificationRead: '/outleap/cinerush/api/setNotificationRead',
    clearNotifications: '/outleap/cinerush/api/clearNotifications',
  },
};
