export const initialState: CoreState = {
  spinner: {
    message: '',
    show: false,
  },
  snackBar: {
    message: '',
    show: false,
  },
  currentUrl: '/',
  bottomSheet: {
    show: false,
    data: {
      accountId: '',
      jobId: '',
    },
  },
};

export interface CoreState {
  spinner: {
    show: boolean;
    message: string;
  };
  snackBar: {
    show: boolean;
    message: string;
  };
  currentUrl: string;
  bottomSheet: {
    show: boolean;
    data: {
      accountId: string;
      jobId: string;
    };
  };
}
