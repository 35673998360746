import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EndorsementsService {
  constructor(private http: HttpClient) {}

  getIndustryProfilesForEndorsement(): Observable<any> {
    return this.http.post(
      environment.api.baseUrl +
        environment.api.getIndustryProfilesForEndorsement,
      {}
    );
  }

  removeEndorsementRequest(request: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.removeEndorsementRequest,
      request
    );
  }

  getEndorsements(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getEndorsements,
      payload
    );
  }

  submitEndorsementRequest(request: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.submitEndorsement,
      request
    );
  }

  updateEndorsementRequest(request: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.updateEndorsement,
      request
    );
  }

  approveOrDeclineEndorsement(request: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.approveOrDenyEndorsement,
      request
    );
  }
}
