import { createAction, props } from '@ngrx/store';

export const getPostedByDetails = createAction('[USER] Get Posted By Details');

export const setPostedByDetails = createAction(
  '[USER] Set Posted By Details',
  props<{ postedByProfile: any }>()
);

export const getNotifications = createAction('[USER] Get Notifications');

export const setNotifications = createAction(
  '[USER] Set Notifications',
  props<{ notifications: any[] }>()
);

export const clearNotifications = createAction('[USER] Clear Notifications');

export const setNotificationRead = createAction(
  '[USER] Set Notification Read',
  props<{ notificationId: string }>()
);
