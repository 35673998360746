<div class="min-wd-300 min-ht-200 dflex-column">
  <h2
    mat-dialog-title
    style="font-weight: bold; font-size: 1.2rem; padding: 20px"
  >
    {{ data.title }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <mat-stepper linear #stepper>
      <mat-step
        [stepControl]="firstFormGroup"
        [editable]="true"
        *ngIf="!data.isEditShortlist && !data.isAddShortlist"
      >
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Project Name</ng-template>
          <p>Please add meaningful project name</p>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Name</mat-label>
            <input
              matInput
              formControlName="title"
              placeholder="Project Name"
              required
            />
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Description</mat-label>
            <input
              matInput
              formControlName="description"
              placeholder="Project Description"
              required
            />
          </mat-form-field>
          <mat-dialog-actions align="end" class="justify-space-bt">
            <button
              class="btn btn-danger danger-btn"
              [mat-dialog-close]="false"
            >
              {{ data.negativeBtnLabel }}
            </button>
            <button class="btn btn-primary save-btn" matStepperNext>
              Next
            </button>
          </mat-dialog-actions>
        </form>
      </mat-step>
      <mat-step
        *ngIf="!data.isEditProject"
        [stepControl]="secondFormGroup"
        [editable]="true"
      >
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Shortlist Name</ng-template>
          <p>Please add shortlist name</p>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Please enter Short list name</mat-label>
            <input
              matInput
              formControlName="secondCtrl"
              placeholder="Shortlist name"
              required
            />
          </mat-form-field>
          <mat-dialog-actions align="end" class="justify-space-bt">
            <button
              class="btn btn-danger danger-btn"
              [mat-dialog-close]="false"
            >
              {{ data.negativeBtnLabel }}
            </button>
            <div>
              <button
                class="btn btn-primary save-btn"
                matStepperPrevious
                style="margin-right: 10px"
              >
                Back
              </button>
              <button class="btn btn-primary save-btn" matStepperNext>
                Next
              </button>
            </div>
          </mat-dialog-actions>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Review and Create</ng-template>
        <p>Verify details before saving</p>
        <div>
          <p>Project Name: {{ firstFormGroup.value.title }}</p>
          <p>Project Description: {{ firstFormGroup.value.description }}</p>
          <p>Shortlist Name: {{ secondFormGroup.value.secondCtrl }}</p>
        </div>
        <mat-dialog-actions align="end" class="justify-space-bt">
          <button class="btn btn-danger danger-btn" [mat-dialog-close]="false">
            {{ data.negativeBtnLabel }}
          </button>
          <div>
            <button
              class="btn btn-primary save-btn"
              matStepperPrevious
              style="margin-right: 10px"
            >
              Back
            </button>
            <button
              *ngIf="data.positiveBtnLabel"
              class="btn btn-primary save-btn"
              (click)="save()"
            >
              {{ data.positiveBtnLabel }}
            </button>
          </div>
        </mat-dialog-actions>
      </mat-step>
    </mat-stepper>
  </mat-dialog-content>
</div>
