import { EndorsementProfile } from 'src/app/endorsements/interfaces/endorsement-profile.interface';
import { EndorsementResponse } from 'src/app/endorsements/interfaces/endorsements-response.interface';
import { IEndorsemtsRequest } from 'src/app/recruiter/interfaces/endorsements-request.interface';

export const initialState: EndorsementsState = {
  endorsementProfiles: [],
  endorsments: [],
  editEndorsement: null,
  endorsementsRequests: [],
};

export interface EndorsementsState {
  endorsments: EndorsementResponse[];
  endorsementProfiles: EndorsementProfile[];
  editEndorsement: EndorsementResponse | null;
  endorsementsRequests: IEndorsemtsRequest[];
}
