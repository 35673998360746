import { ProfileInterface } from '../../shared/interfaces/profile.interface';

export const initialState: ProfileState = {
  profile: {
    demographic: {
      firstName: '',
      lastName: '',
      city: '',
      emailId: '',
      zipCode: 0,
      gender: '',
      phoneNumber: '',
      dob: '',
      bio: '',
      registerType: '',
      accountId: '',
      country: '',
      ethnicity: '',
      state: '',
      nationality: '',
    },
    skillOptions: [],
    lookingFor: [],
    mediaLinks: [],
    videoLinks: [],
    workExperience: [],
    educationDetails: [],
    physicalAttributes: {
      weight: '',
      height: '',
      bodyType: '',
      eyecolor: '',
      hairType: '',
      skintone: '',
      hairColor: '',
      specialPhysicalAttributes: [],
    },
    uniqueTalentAbility: {
      physicalSkills: [],
      combatWeaponSkills: [],
      danceMovement: [],
      performanceArts: [],
      adventureSurvivalSkills: [],
      culturalLinguisticSkills: [],
      uniquePhysicalTalents: [],
      stuntHighRiskSkills: [],
      artisticTechnicalSkills: [],
      miscellaneousSkills: [],
    },
    profileImage: '',
    portfolio0: '',
    portfolio1: '',
    portfolio2: '',
    portfolio3: '',
    signedProfileImageUrl: '',
    signedportfolio0Url: '',
    signedportfolio1Url: '',
    signedportfolio2Url: '',
    signedportfolio3Url: '',
    languages: [],
    allowProfileVisibilty: false,
    isRecruiterBookMarkedProfile: false,
    isRecruiterLikedProfile: false,
    profileLikedCount: 0,
    notes: '',
  },
  registerType: '',
  getProfileDetailsError: '',
  allProfiles: [],
  appliedProfile: {
    profileAccountId: '',
    jobId: '',
    profileData: {
      demographic: {
        firstName: '',
        lastName: '',
        city: '',
        emailId: '',
        zipCode: 0,
        gender: '',
        phoneNumber: '',
        dob: '',
        bio: '',
        registerType: '',
        accountId: '',
        country: '',
        ethnicity: '',
        state: '',
        nationality: '',
      },
      skillOptions: [],
      lookingFor: [],
      mediaLinks: [],
      videoLinks: [],
      workExperience: [],
      educationDetails: [],
      physicalAttributes: {
        weight: '',
        height: '',
        bodyType: '',
        eyecolor: '',
        hairType: '',
        skintone: '',
        hairColor: '',
        specialPhysicalAttributes: [],
      },
      uniqueTalentAbility: {
        physicalSkills: [],
        combatWeaponSkills: [],
        danceMovement: [],
        performanceArts: [],
        adventureSurvivalSkills: [],
        culturalLinguisticSkills: [],
        uniquePhysicalTalents: [],
        stuntHighRiskSkills: [],
        artisticTechnicalSkills: [],
        miscellaneousSkills: [],
      },
      profileImage: '',
      portfolio0: '',
      portfolio1: '',
      portfolio2: '',
      portfolio3: '',
      signedProfileImageUrl: '',
      signedportfolio0Url: '',
      signedportfolio1Url: '',
      signedportfolio2Url: '',
      signedportfolio3Url: '',
      languages: [],
      allowProfileVisibilty: false,
      isRecruiterBookMarkedProfile: false,
      isRecruiterLikedProfile: false,
      profileLikedCount: 0,
      notes: '',
    },
  },
  isAppliedProfileFailure: false,
  isAppliedProfileSuccess: false,
  redirectToUserProfile: false,
};

export interface ProfileState {
  profile: ProfileInterface;
  registerType: string;
  getProfileDetailsError: string;
  allProfiles: ProfileInterface[];
  appliedProfile: {
    profileAccountId: string;
    jobId: string;
    profileData: ProfileInterface;
  };
  isAppliedProfileSuccess: boolean;
  isAppliedProfileFailure: boolean;
  redirectToUserProfile: boolean;
}
