import { createAction, props } from '@ngrx/store';
import { IndependentProfileInterface } from 'src/app/recruiter/interfaces/independent-profile.interface';
import { IShortlist } from 'src/app/recruiter/interfaces/projects.interface';
import { RecruiterProfileInterface } from 'src/app/recruiter/interfaces/recruiter-profile.interface';

export const updateRecruiterDetails = createAction(
  '[Recruiter] Update Recruiter Details',
  props<{
    emailId: string;
    registerType: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    accountId: string;
  }>()
);

export const updateIndFilmMakerDetails = createAction(
  '[Recruiter] Update IndependentFilmMaker Details',
  props<{
    emailId: string;
    registerType: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    accountId: string;
  }>()
);

export const getRecruiterDetails = createAction(
  '[Recruiter] Get Recruiter Details'
);

export const showRecruiterProfile = createAction(
  '[Recruiter] Show Recruiter Profile',
  props<{ showRecruiterProfile: boolean }>()
);

export const getRecruiterDetailsSuccess = createAction(
  '[Recruiter] Get Recruiter Details Success',
  props<{ payload: any }>()
);
export const getRecruiterDetailsError = createAction(
  '[Recruiter] Get Recruiter Details Error',
  props<{ error: boolean; message: string }>()
);

export const getJobsCreated = createAction(
  '[Recruiter] Get Jobs Created',
  props<{ filter: {} }>()
);

export const getJobsCreatedSuccess = createAction(
  '[Recruiter] Get Jobs Created Success',
  props<{ payload: any }>()
);

export const getJobsCreatedError = createAction(
  '[Recruiter] Get Jobs Created Error',
  props<{ error: boolean; message: string }>()
);

export const updateRecruiterProfileDetails = createAction(
  '[Recruiter] Update Recruiter Profile Details',
  props<{ payload: RecruiterProfileInterface }>()
);

export const updateRecruiterProfileDetailsSuccess = createAction(
  '[Recruiter] Update Recruiter Profile Details Success',
  props<{ payload: any }>()
);

export const updateRecruiterProfileDetailsError = createAction(
  '[Recruiter] Update Recruiter Profile Details Error',
  props<{ error: boolean; message: string }>()
);

export const setProfilesFilter = createAction(
  '[Recruiter] Set Profiles Filter',
  props<{ filter: {} }>()
);

export const updateLikeForUserProfile = createAction(
  '[Recruiter] Update Like For User Profile',
  props<{
    interactionFlag: boolean;
    interactedAccountId: string;
    jobId: string;
  }>()
);

export const bookmarkUserProfile = createAction(
  '[Recruiter] Update Bookmark For User Profile',
  props<{
    interactedAccountId: string;
    interactionFlag: boolean;
    jobId: string;
  }>()
);

export const setViewJobDetailId = createAction(
  '[Recruiter] Set View Job Detail Id',
  props<{ id: string }>()
);

export const updateIndFilmMakerProfileDetails = createAction(
  '[Recruiter] Update Independent Film Maker Profile Details',
  props<{ payload: IndependentProfileInterface }>()
);

export const updateIndFilmMakerProfileDetailsSuccess = createAction(
  '[Recruiter] Update Independent Film Maker Profile Details Success',
  props<{ payload: any }>()
);

export const updateIndFilmMakerProfileDetailsError = createAction(
  '[Recruiter] Update Independent Film Maker Profile Details Error',
  props<{ error: boolean; message: string }>()
);

export const getIndFilmMakerProfileDetails = createAction(
  '[Recruiter] Get Independent Film Maker Profile Details'
);

export const getIndFilmMakerProfileDetailsSuccess = createAction(
  '[Recruiter] Get Recruiter Independent Film Maker Profile Success',
  props<{ payload: any }>()
);
export const getIndFilmMakerProfileDetailsError = createAction(
  '[Recruiter] Get Independent Film Maker Profile Details Error',
  props<{ error: boolean; message: string }>()
);

export const saveNotesForUserProfile = createAction(
  '[Recruiter] Save Recruiter Notes On User Profile',
  props<{ targetedAccountId: string; notes: string }>()
);

export const getProjects = createAction('[Recruiter] Get Projects');

export const updateProjects = createAction(
  '[Recruiter] Update Projects',
  props<{ projects: any }>()
);

export const createProject = createAction(
  '[Recruiter] Create Project',
  props<{
    title: string;
    description: string;
    shortlists: IShortlist[];
    isActive: boolean;
    projectId?: string;
  }>()
);

export const updateShortlistName = createAction(
  '[Recruiter] Update Shortlist Name',
  props<{
    projectId: string;
    deleteShortlist: boolean;
    isShortListNameUpdated: boolean;
    shortlistName: string;
    oldshortlistName?: string;
  }>()
);

export const updateShortlistProfiles = createAction(
  '[Recruiter] Update Shortlist Profiles',
  props<{
    projectId: string;
    shortListName: string;
    targetedAccountId: string;
  }>()
);
